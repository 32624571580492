footer {
    background-color: rgb(34, 30, 30);
    padding: 16px 32px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  
  footer p {
    color: #ccc;
  }
  