
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.75); 
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
  }
  
  .popup-inner {
    text-align: center;
  }
  
  .close-btn {
    position: absolute;
    top: 10px; 
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .todays-speacial-image{
    height: 150px;
    width: 150px;
    border-radius: 8px;
  }