.menu-container {
  
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.menu-list {
  background-color: rgba(255, 255, 255, 0.65); 
  border-radius: 8px;
  list-style-type: none;
  padding: 20px;
}

.menu-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  display: flex;
  justify-content: space-between; /* Add space between items */
  align-items: center; /* Center items vertically */
}

.item-name {
  font-size: 20px;
}

.item-price {
  font-weight: bold;
  margin-right: 400px; 
}

@media screen and (max-width: 768px) {
  .item-name {
    font-size: 16px; 
  }

  .item-price {
    font-size: 16px; 
    margin-right: 200px;
  }
}
.back-button{
  display: flex;
  justify-content: flex-start; 
  align-items: center; 
  align-content: space-between;
  /* border: none;
  background: none;
  cursor: pointer; */
}