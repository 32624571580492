header {
    background-color: rgb(34, 30, 30);
    padding: 16px 32px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: flex-end; 
}

.icon {
    margin-left: 10px;
}

header h4 {
    color: #fff;
}
