.imageButton {
  position: relative;
  height: 200px; 
  width: 25%;
  margin: 10px; 
  overflow: hidden;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .imageButton{
    width: 80%;
  }
  .imageText {
    
    font-size: small;
    
  }
}

.imageButton:hover, .imageButton:focus-visible {
  z-index: 1;
}

.imageBackdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.3s;
}

.imageButton:hover .imageBackdrop, 
.imageButton:focus-visible .imageBackdrop {
  opacity: 0.15;
}

.imageMarked {
  height: 3px;
  width: 18px;
  background-color: #ffffff;
  position: absolute;
  bottom: -2px;
  left: calc(50% - 9px);
  opacity: 0;
  transition: opacity 0.3s;
}

.imageButton:hover .imageMarked, 
.imageButton:focus-visible .imageMarked {
  opacity: 1;
}

.imageSrc {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center 40%;
}

.image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.imageText {
  color: black;
  background-color: rgba(255, 255, 255, 0.75); 
  font-size: xx-large ;
  border: 4px solid transparent;
  font-weight:bolder ;
}

.imageText:hover, .imageText:focus-visible {
  border-color: #ffffff;
}
