* {
    padding: 0;
    box-sizing: border-box;
    font-family: "Amatic SC", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  body {
    background-image: url('../public/images/background1.jpg'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    margin: 0; 
    padding: 0; 
  }
  
   
  
@media screen and (max-width: 769px) {
    body {
    background-position: 100%; 
    }
  }
  
  