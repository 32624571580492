

.container {
    margin: 0; 
    padding: 0 ; 
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 300px;
    width: 100%;
  }
  
  